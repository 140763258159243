const dev = process.env.NODE_ENV !== 'production';

//export const server = dev ? 'http://mortens-macbook-pro.local:3000' : '';
export const server = dev ? 'http://localhost:3000' : '';

export const AFFILIATES_LINKS = {
    Nytelse : {
        url: 'http://www.nytelse.no/tt/?tt=12509_620063_263403_&amp;r='
    },
    Kondomeriet: {
        url: 'https://tc.tradetracker.net/?c=31748&amp;m=1690139&amp;a=263403&amp;r=&amp;u='
    },
    Lovetoys: {
        url: 'http://www.lovetoys.no/tt/?tt=15703_585498_263403_&amp;r='
    },
    Superkul: {
        url: 'https://www.superkul.no/tt/?tt=12793_461488_263403_&amp;r='
    },
    Erotikknett: {
        url: 'http://clk.tradedoubler.com/click?p=139403&a=2485648&g=18279424'
    },
    PassionFruit: {
        url: 'http://clk.tradedoubler.com/click?p=203877&a=2485648&g=19738922'
    },
    Sinful: {
        url: 'https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=55836'
    },
    Amor: {
        url: 'https://amor.no/tt/?tt=33201_12_263403_&amp;r='
    },
	Joyland: {
		url: 'https://tc.tradetracker.net/?c=36932&m=12&a=263403&r='
	}
};

export const AFFILIATE_LIST = [
    'Kondomeriet', 'LoveToys', 'Sinful', 'Passionfruit', 'Amor', 'Joyland'
];

export enum AFFILIATE {
    NYTELSE = 'NYTELSE',
    KONDOMERIET = 'KONDOMERIET',
    LOVETOYS = 'LOVETOYS',
    EROTIKKNETT = 'EROTIKKNETT',
    PASSIONFRUIT = 'PASSIONFRUIT',
    SUPERKUK = 'SUPERKUL',
    SINFUL = 'SINFUL',
    AMOR = 'AMOR',
	JOYLAN = 'JOYLAND'
}