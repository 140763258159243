import { useRouter } from "next/router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IProduct } from "../../model/product";
import styles from './product-small-tile.module.css';
import product from "../../pages/api/product";
import Link from "next/link";
export default function ProductSmallTile(props: {product: IProduct, size: 'normal' | 'xs', detailsPath: string}) {
	const router = useRouter();
	let stars = '&#9734;&#9734;&#9734;&#9734;&#9734;';
	if (props.product.rating){
		stars = '';
		for(let i = 0; i <= 4; i++) {
			if (i <= props.product.rating) {
				stars += '&#9733;';
			} else {
				stars += '&#9734;';
			}
		}
	}

	
	if (props.product.rating == -1) {
		stars = '&nbsp;';
	}

	return (
		<Link href={`${props.detailsPath?.toLowerCase()}/${props.product.name}`}>
			<div key={props.product?._id} className={styles.container}>
				<div className={styles.headerContainer}>{props.product.name}</div>
				<LazyLoadImage className={styles.image} src={props.product.image} alt={props.product.name} /><br/>
				<div className={styles.stars} dangerouslySetInnerHTML={{__html: stars}} style={{display: 'none'}}></div>
				<br/>
				<div>
					<div className={styles.readMoreBtn} style={{display: 'inline'}}>
						Fra {props.product.price},- NOK
					</div>
				</div>
				<br/>
			</div>
		</Link>
	)
}